<template>
  <sm-page-loader v-if="projects.isLoading" />

  <div v-else class="editable-list">
    <sm-breadcrumbs class="editable-list__breadcrumbs" :items="breadcrumbs" />

    <p v-if="!projects.data.length" class="deploy-alert">
      Нет доступных проектов
    </p>

    <sm-datatable
      v-else
      class="editable-list__datatable"
      :caption="tableCaption"
      :headers="tableHeaders"
      :items="projects.data"
    >
      <template v-slot:actions="{ row }">
        <div class="editable-list__datatable-buttons">
          <button
            class="editable-list__datatable-button"
            @click="onViewDetail(row.id)"
          >
            <sm-icon name="eye" />
          </button>
        </div>
      </template>
    </sm-datatable>
  </div>
</template>

<script>
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('deploy');

// components
import SmPageLoader from '@/components/common/SmPageLoader.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';
import SmIcon from '@/components/common/SmIcon.vue';

export default {
  name: 'Deploy',

  components: {
    SmPageLoader,
    SmBreadcrumbs,
    SmDatatable,
    SmIcon,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: 'Публикация приложений',
          route: { name: 'PublishingApplicationsMain' },
        },
        {
          text: 'Проекты',
        },
      ],
      tableCaption: 'Проекты',
      tableHeaders: [
        {
          text: '№',
          alias: 'id',
        },
        {
          text: 'Название',
          alias: 'name',
        },
        {
          text: 'Дата добавления последнего релиза',
          alias: 'lastReleaseCreated',
        },
        {
          text: 'Автор последний релиз',
          alias: 'lastReleaseAuthor',
        },
        {
          text: 'Количество добавленных релизов',
          alias: 'releaseCount',
        },
        {
          alias: 'actions',
        },
      ],
    };
  },

  computed: {
    ...mapState(['projects']),
  },

  created() {
    this.getProjects();
  },

  methods: {
    ...mapActions(['getProjects']),

    onViewDetail(id) {
      this.$router.push({ name: 'DeployDetail', params: { id } });
    },
  },
};
</script>

<style lang="scss">
.editable-list__breadcrumbs {
  margin-bottom: 15px;
}

.deploy-alert {
  padding: 20px;

  font-size: 20px;
  color: var(--gray);
  font-weight: bold;

  border-radius: 10px;
  background-color: var(--light-gray);
}

.editable-list__datatable-buttons {
  text-align: center;
}

.editable-list__datatable-button {
  color: var(--gray);
}

.editable-list__datatable-button {
  &:hover {
    color: var(--blue);
  }
}
</style>
